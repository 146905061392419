
















































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import { Comp, Dict, Report as StReport } from '../types';
import I18n from '../I18n';
import fieldInfoFormMap from './form-config';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IRow extends Comp.TableRow<Record<string, unknown>> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ICellData extends Comp.TableCellRenderData<IRow> {}


const i18n = new I18n('modules.budget.staffing_table.old_v1_reports.*Report*');


@Component
export default class Report extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('reportId', () => {
            this.reload();
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        this.reload();
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }
    // endregion


    // region Параметры страницы
    private get reportId(): number | null {
        const params = this.$route.params;

        const idString = params.id;
        const id = parseInt(idString);
        if (String(id) !== idString) {
            return null;
        }
        return id;
    }
    // endregion


    // region Загрузка данных
    // noinspection JSUnusedLocalSymbols
    private get loading(): boolean {
        return (this.loadingReport || this.loadingRows);
    }

    private reload() {
        this.reloadReport();
        this.reloadRows();
    }
    // endregion


    // region Отчет
    private loadingReport = false;

    private report: StReport | null = null;

    private get displayedFields(): string[] | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        const result = report.displayedFields
            .split(',')
            .map(fieldName => fieldName.trim())
            .filter(fieldName => (fieldName.length > 0));
        result.unshift('order_number');

        /*
        const fieldInfoMap = fieldInfoFormMap[report.form];
        if (fieldInfoMap !== undefined) {
            const order = Object.getOwnPropertyNames(fieldInfoMap);
            result.sort((fieldName1, fieldName2) => {
                const index1 = order.indexOf(fieldName1);
                const index2 = order.indexOf(fieldName2);
                return index1 - index2;
            });
        }
        */

        return result;
    }

    private get reportFieldInfoMap(): Record<string, StReport.FieldInfo> | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        const displayedFields = this.displayedFields;
        if (displayedFields === null) {
            return null;
        }

        const result: Record<string, StReport.FieldInfo> = {};

        const columns = report.columns;
        // Если в отчете уже есть колонки, то надо брать их вместо колонок по умолчанию
        // иначе - берется информация о колонках по умолчанию
        if ((columns !== null) && (columns.length > 0)) {
            const isKazakh = this.i18n.isKazakh;
            columns.forEach(column => {
                const fieldKey = column.field;
                if (displayedFields.indexOf(fieldKey) >= 0) {
                    let title: string;
                    let subtitle: string | null;
                    if (isKazakh) {
                        title = column.titleKk;
                        subtitle = column.subtitleKk;
                    } else {
                        title = column.titleRu;
                        subtitle = column.subtitleRu;
                    }

                    result[fieldKey] = {
                        title: title,
                        subtitle: (subtitle || undefined),
                        isDateField: column.dateField
                    };
                }
            });
        } else {
            const fieldInfoMap = fieldInfoFormMap[report.form];
            if (fieldInfoMap === undefined) {
                return null;
            }

            Object.getOwnPropertyNames(fieldInfoMap).forEach(fieldName => {
                if (displayedFields.indexOf(fieldName) >= 0) {
                    result[fieldName] = fieldInfoMap[fieldName];
                }
            });
        }

        return result;
    }

    private get reportPrefixFieldInfoMap(): Record<string, StReport.FieldInfo> | null {
        const reportFieldInfoMap = this.reportFieldInfoMap;
        if (reportFieldInfoMap === null) {
            return null;
        }

        const result: Record<string, StReport.FieldInfo> = {};
        Object.getOwnPropertyNames(reportFieldInfoMap).forEach(key => {
            const prefixKey = `data.${key}`;
            result[prefixKey] = reportFieldInfoMap[key];
        });
        return result;
    }

    private get reportTableFieldDefs(): Comp.TableFieldDef[] | null {
        const reportPrefixFieldInfoMap = this.reportPrefixFieldInfoMap;
        if (reportPrefixFieldInfoMap === null) {
            return null;
        }

        const displayedFields = this.displayedFields;
        if (displayedFields === null) {
            return null;
        }

        const result: Comp.TableFieldDef[] = [];
        displayedFields.forEach(key => {
            const dataKey = `data.${key}`;
            let label = '';
            const info = reportPrefixFieldInfoMap[dataKey];
            // noinspection JSIncompatibleTypesComparison
            if (info !== undefined) {
                label = info.title;
            }
            const fieldDef: Comp.TableFieldDef = {
                key: dataKey,
                label
            };
            result.push(fieldDef);
        });
        return result;
    }

    private reloadReport() {
        if (this.loadingReport) {
            console.error('Cannot load report, another loading is running');
            return;
        }

        const id = this.reportId;
        if (id === null) {
            console.error('Cannot load report, ID is null');
            return;
        }

        this.loadingReport = true;
        this.report = null;
        Ax<StReport>(
            { url: `/api/budget/staffing_table/db/report/${id}` },
            report => {
                this.report = report;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_report'), error.toString()),
            () => {
                this.loadingReport = false;
            }
        );
    }

    private getHeaderText(fieldName: string): string | null {
        const reportPrefixFieldInfoMap = this.reportPrefixFieldInfoMap;
        if (reportPrefixFieldInfoMap === null) {
            return null;
        }

        const info = reportPrefixFieldInfoMap[fieldName];
        // noinspection JSIncompatibleTypesComparison
        if (info === undefined) {
            return null;
        }

        return info.title || null;
    }

    private getSubheaderText(fieldName: string): string | null {
        const reportPrefixFieldInfoMap = this.reportPrefixFieldInfoMap;
        if (reportPrefixFieldInfoMap === null) {
            return null;
        }

        const info = reportPrefixFieldInfoMap[fieldName];
        // noinspection JSIncompatibleTypesComparison
        if (info === undefined) {
            return null;
        }

        return info.subtitle || null;
    }

    private get abp(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.abpObject;
    }

    private get abpText(): string | null {
        const abp = this.abp;
        if (abp === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = abp.nameKk;
        } else {
            title = abp.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = abp.abp;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get funcGroup(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.funcGroupObject;
    }

    private get funcGroupText(): string | null {
        const funcGroup = this.funcGroup;
        if (funcGroup === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = funcGroup.nameKk;
        } else {
            title = funcGroup.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = funcGroup.gr;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get funcSubgroup(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.funcSubgroupObject;
    }

    private get funcSubgroupText(): string | null {
        const funcSubgroup = this.funcSubgroup;
        if (funcSubgroup === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = funcSubgroup.nameKk;
        } else {
            title = funcSubgroup.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = funcSubgroup.pgr;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get budgetProgram(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.budgetProgramObject;
    }

    private get budgetProgramText(): string | null {
        const budgetProgram = this.budgetProgram;
        if (budgetProgram === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = budgetProgram.nameKk;
        } else {
            title = budgetProgram.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = budgetProgram.prg;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get specificity(): Dict.EbkEk | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.specificityObject;
    }

    private get specificityText(): string | null {
        const specificity = this.specificity;
        if (specificity === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = specificity.nameKk;
        } else {
            title = specificity.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = specificity.spf;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }
    // endregion


    // region Строки
    private loadingRows = false;

    private reportData: StReport.ReportData | null = null;

    private get rows(): IRow[] | null {
        const data = this.reportData;
        if (data === null) {
            return null;
        }
        return data.rows.map(dataRow => {
            const row: IRow = {
                id: String(dataRow.id),
                data: dataRow,
                original: null,
                inputValues: {},
                errors: {},
                _rowVariant: null,
                invalid: false,
                changed: false,
                selected: false
            };
            return row;
        });
    }

    private get totals(): Map<string, number> | null {
        const reportData = this.reportData;
        if (reportData === null) {
            return null;
        }

        const reportFieldInfoMap = this.reportFieldInfoMap;
        if (reportFieldInfoMap === null) {
            return null;
        }

        const result = new Map<string, number>();
        reportData.rows.forEach(row => {
            Object.getOwnPropertyNames(reportFieldInfoMap).forEach(fieldName => {
                if (reportData.dateFields.indexOf(fieldName) < 0) {
                    const value = row[fieldName];
                    if (typeof value === 'number') {
                        let totalValue: number | undefined = result.get(fieldName);
                        if (totalValue === undefined) {
                            totalValue = 0;
                        }
                        totalValue += value;
                        result.set(fieldName, totalValue);
                    }
                }
            });
        });

        return result;
    }

    private reloadRows() {
        if (this.loadingRows) {
            console.error('Cannot load report rows, another loading is running');
            return;
        }

        const reportId = this.reportId;
        if (reportId === null) {
            console.error('Cannot load report rows, report ID is null');
            return;
        }

        this.loadingRows = true;
        this.reportData = null;
        Ax<StReport.ReportData>(
            { url: `/api/budget/staffing_table/db/report/${reportId}/rows` },
            data => {
                this.reportData = data;
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_report_rows'), error.toString()),
            () => {
                this.loadingRows = false;
            }
        );
    }

    private getFieldInfo(data: ICellData): StReport.FieldInfo | null {
        let result: StReport.FieldInfo | undefined;
        (() => {
            const fieldDef = data.field;
            // noinspection SuspiciousTypeOfGuard
            if (!(fieldDef instanceof Object)) {
                return;
            }

            const key = fieldDef.key;
            if (typeof key !== 'string') {
                return;
            }

            const reportFieldInfoMap = this.reportPrefixFieldInfoMap;
            if (reportFieldInfoMap === null) {
                return;
            }

            result = reportFieldInfoMap[key];
        })();
        return (result || null);
    }

    private renderCellValue(data: ICellData): unknown {
        const fieldInfo = this.getFieldInfo(data);

        const rawValue = data.value;
        if (typeof rawValue === 'number') {
            if (fieldInfo?.isDateField) {
                return this.dateFormat.format(rawValue);
            }
            return this.$n(rawValue);
        }
        return rawValue;
    }

    private getTotalValue(fieldName: string): number | null {
        const result = this.totals?.get(fieldName);
        if (typeof result === 'number') {
            return result;
        }
        return null;
    }

    private getTotalValueText(fieldName: string): string | null {
        const number = this.getTotalValue(fieldName);
        if (typeof number === 'number') {
            return this.$n(number);
        }
        return null;
    }

    // noinspection JSMethodCanBeStatic
    private isDebugField(data: ICellData): boolean {
        return (data.field?.key === 'data.debug_info');
    }
    // endregion
}
